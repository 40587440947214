import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            My role involves 
              <i>
                <b className="purple"> designing </b>
              </i>,
              <i>
                <b className="purple"> building </b>
              </i>, 
              <i>
                <b className="purple"> managing </b>
              </i>, and <i>
                <b className="purple"> maintaining </b>
              </i> the IT environment that companies need to operate internally, collect data, develop and launch digital products, support online stores, and achieve other business objectives.
              <br />
              <br />
              Developing IT infrastructures for both 
              <i>
                <b className="purple"> on-premise </b>
              </i>and
              <i>
                <b className="purple"> cloud environments </b>
              </i>, or a combination of the two, to ensure 
              <i>
                <b className="purple"> maximum availability </b>
              </i>according to your requirements.
              <br />
              <br />
              I love to build 
              <i>
                <b className="purple"> functional</b>
              </i>,
              <i>
                <b className="purple"> secure </b>
              </i>and
              <i>
                <b className="purple"> resilient </b>
              </i>networks, especially if they include
              <i>
                <b className="purple"> Mikrotik </b>
              </i>hardware.
              <br />
              <br />
              I also apply my passion for design on platforms like 
              <i>
                <b className="purple"> Azure </b>
              </i>,
              <i>
                <b className="purple"> AWS </b>
              </i>, and 
              <i>
                <b className="purple"> DigitalOcean </b>
              </i>and use many virtualization platforms like 
              <i>
                <b className="purple"> ESXi </b>
              </i>and 
              <i>
                <b className="purple"> Proxmox </b>
              </i>.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/dmatsoukas"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/dmatsoukass/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
