import React from "react";
import { Col, Row } from "react-bootstrap";
import { SiMikrotik, SiMicrosoftazure, SiKubernetes, SiVmware, SiProxmox, SiTruenas, SiCisco, SiElastic } from "react-icons/si";
import { FaAws, FaDigitalOcean, FaDocker, FaRedhat, FaGithub, FaCloudflare} from "react-icons/fa";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftazure />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaAws />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaDigitalOcean />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKubernetes />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaDocker />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVmware />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiProxmox />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTruenas />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMikrotik />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCisco />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaRedhat />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaGithub />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiElastic />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaCloudflare />
      </Col>
    </Row>
  );
}

export default Techstack;
